<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-payment modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="top-bar" v-if="paymentData.method !== 'scanQR' && paymentData.method !== 'prepaidQR'">
                            <div class="title">
                                <img src="@/assets/gbprime.svg" alt="GBPrime" title="GBPrime" />
                                <div class="by">
                                    <h4>
                                        <b-icon icon="credit-card"></b-icon>
                                        {{ paymentTitle }}
                                    </h4>
                                    <span>powered by GBPrime</span>
                                </div>
                            </div>
                        </div>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>

                        <div class="form" v-if="paymentData.method === 'card' || paymentData.method === 'prepaidCard'">
                            <div class="card-logo">
                                <img src="@/assets/icon-card-visa.webp" alt="VISA" title="VISA" />
                                <img src="@/assets/icon-card-master.webp" alt="MASTER" title="MASTER" />
                                <img src="@/assets/icon-card-jcb.webp" alt="JCB" title="JCB" />
                            </div>
                            <div class="input-field">
                                <label>Card Number</label>
                                <input type="tel"
                                    autocomplete="off"
                                    v-model="cardNo"
                                    @keypress="$AppUtility.inputOnlyNumber($event)"
                                    maxlength="19"
                                    placeholder="xxxx xxxx xxxx xxxx"
                                    :class="{ 'invalid': error.cardNo }" />
                            </div>
                            <div class="input-field">
                                <label>Expiry Date (mm/yyyy)</label>
                                <select
                                    v-model="form.expiryMonth"
                                    :class="{ 'invalid': error.expiryMonth }">
                                    <option value="" disabled selected>-- MM --</option>
                                    <option v-for="(item, index) in optionMonth" :key="index" :value="item.value">{{ item.text }}</option>
                                </select>
                            </div>
                            <div class="input-field">
                                <select
                                    v-model="form.expiryYear"
                                    :class="{ 'invalid': error.expiryYear }">
                                    <option value="" disabled selected>-- YY --</option>
                                    <option v-for="(item, index) in optionYear" :key="index" :value="item.value">{{ item.text }}</option>
                                </select>
                            </div>
                            <div class="input-field">
                                <label>CCV</label>
                                <input type="password"
                                    autocomplete="off"
                                    v-model="form.ccv"
                                    maxlength="3"
                                    placeholder="CCV"
                                    :class="{ 'invalid': error.ccv }" />
                            </div>

                            <form id="formSecurePage" name="form" :action="helper.gbConfig().apiUrl + '/v2/tokens/3d_secured'" method="POST">
                                <input type="hidden" name="publicKey" :value="helper.gbConfig().publicKey" />
                                <input type="hidden" name="gbpReferenceNo" :value="gbpReferenceNo" />
                            </form>
                        </div>

                        <div class="qr-code" v-if="paymentData.method === 'scanQR' || paymentData.method === 'prepaidQR'">
                            <img :src="qrcode.image" v-if="qrcode" />
                            <div class="loading" v-else>
                                <loader-spinner
                                    class="spinner"
                                    sizeWidth="25px"
                                    sizeHeight="25px">
                                </loader-spinner>
                            </div>

                            <div class="download" v-if="qrcode">
                                <a :href="qrcode.image" :download="'yellowtire-qrpayment-' + qrcode.date" v-if="qrcode">ดาวน์โหลด QR</a>
                            </div>
                        </div>

                        <div class="form" v-if="paymentData.method === 'internetBanking' || paymentData.method === 'prepaidIBanking'">
                            <div class="bank-logo">
                                <img src="@/assets/app-bank.webp" alt="Bank Associate" title="Bank Associate" />
                            </div>
                            <form id="formMobileBanking" :action="helper.gbConfig().apiUrl + '/v2/mobileBanking'" method="POST">
                                <input type="hidden" name="publicKey" :value="helper.gbConfig().publicKey" />
                                <input type="hidden" name="customerTelephone" :value="gbBanking.customerTelephone" />
                                <input type="hidden" name="referenceNo" :value="gbBanking.referenceNo" />
                                <input type="hidden" name="backgroundUrl" :value="gbBanking.backgroundUrl" />
                                <input type="hidden" name="responseUrl" :value="gbBanking.responseUrl" />
                                <input type="hidden" name="amount" :value="gbBanking.amount" />
                                <input type="hidden" name="customerName" :value="gbBanking.customerName" />
                                <input type="hidden" name="customerEmail" :value="gbBanking.customerEmail" />
                                <input type="hidden" name="checksum" :value="gbBanking.checksum" />

                                <div class="input-field">
                                    <label>เลือกธนาคาร</label>
                                    <select
                                        v-model="form.bankCode"
                                        name="bankCode"
                                        :class="{ 'invalid': error.bankCode }">
                                        <option value="" disabled selected>-- เลือกธนาคาร --</option>
                                        <option v-for="(item, index) in optionMobileBanking" :key="index" :value="item.value">{{ item.text }}</option>
                                    </select>
                                </div>
                            </form>
                        </div>

                        <div class="form" v-if="paymentData.method === 'installment1' || paymentData.method === 'installment2'">
                            <div class="card-logo">
                                <img src="@/assets/icon-card-visa.webp" alt="VISA" title="VISA" />
                                <img src="@/assets/icon-card-master.webp" alt="MASTER" title="MASTER" />
                                <img src="@/assets/icon-card-jcb.webp" alt="JCB" title="JCB" />
                            </div>
                            <form id="formInstallment" :action="helper.gbConfig().apiUrl + '/v3/installment'" method="POST"> 
                                <input type="hidden" name="publicKey" :value="helper.gbConfig().publicKey" />
                                <input type="hidden" name="referenceNo" :value="gbInstallment.referenceNo" />
                                <input type="hidden" name="responseUrl" :value="gbInstallment.responseUrl" />
                                <input type="hidden" name="backgroundUrl" :value="gbInstallment.backgroundUrl" />
                                <input type="hidden" name="amount" :value="gbInstallment.amount" />
                                <input type="hidden" name="customerName" :value="gbInstallment.customerName" />
                                <input type="hidden" name="customerTelephone" :value="gbInstallment.customerTelephone" />
                                <input type="hidden" name="customerEmail" :value="gbInstallment.customerEmail" />
                                <input type="hidden" name="detail" :value="gbInstallment.detail" />
                                <input type="hidden" name="term" :value="gbInstallment.term" />
                                <input type="hidden" name="checksum" :value="gbInstallment.checksum" />

                                <div class="input-field">
                                    <label>เลือกบัตรเครดิต</label>
                                    <select
                                        v-model="form.bankCode"
                                        name="bankCode"
                                        :class="{ 'invalid': error.bankCode }">
                                        <option value="" disabled selected>-- เลือกบัตรเครดิต --</option>
                                        <option v-for="(item, index) in optionInstallmentBank" :key="index" :value="item.value">{{ item.text }}</option>
                                    </select>
                                </div>
                            </form>
                        </div>

                        <div class="operation" v-if="paymentData.method !== 'scanQR' && paymentData.method !== 'prepaidQR'">
                            <button class="btn btn-action square" @click="submitPayment()" v-if="paymentData.method">ชำระเงิน {{ paidText }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import moment from 'moment';
import Constants from '@/variables/constants';
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import OrderService from '@/services/orderService';
import GTMService from '@/services/gtmService';
import LoaderSpinner from '@/components/utility/LoaderSpinner';

export default {
    components: {
		LoaderSpinner
	},
    data() {
		return {
            isActive: false,
            helper: Helper,
            cardNo: '',
            paymentTitle: '',
            paidText: '',
            form: null,
            error: null,
            optionMonth: this.initOptionMonth(),
            optionYear: this.initOptionYear(),
            optionMobileBanking: this.initOptionMobileBanking(),
            optionInstallmentBank: this.initOptionInstallmentBank(),
            qrcode: null,
            qrOrderId: '',
            intervalQR: null, 
            gbpReferenceNo: '',
            gbBanking: {
                customerTelephone: '',
                referenceNo: '',
                backgroundUrl: '',
                responseUrl: '',
                amount: '',
                customerName: '',
                customerEmail: '',
                checksum: ''
            },
            gbInstallment: {
                referenceNo: '',
                backgroundUrl: '',
                responseUrl: '',
                amount: '',
                customerName: '',
                customerTelephone: '',
                customerEmail: '',
                checksum: ''
            }
		};
	},
	props: {
        isDisplay: Boolean,
		paymentData: Object,
        cartData: Object
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);

            if (value) {
                this.initForm();
            }
        },
        cardNo() {
            if (this.cardNo) {
                const realNumber = this.cardNo.replace(/ /gi, '');
                const dashedNumber = realNumber.match(/.{1,4}/g);

                this.cardNo = dashedNumber.join(' ');
                this.form.cardNo = this.cardNo;
            }
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide() {
            clearInterval(this.intervalQR);
            this.$emit('close');
        },
        initForm() {
            switch (this.paymentData.method) {
                case 'scanQR':
                case 'prepaidQR':
                    this.paymentTitle = 'สแกน QR';
                    this.paidText = this.paymentData.paid + ' บาท';

                    // Initial QR Image
                    this.initQRPayment();

                    // Set Interval Check Status
                    this.intervalQR = setInterval(this.checkQRStatus, 3000);
                    break;
                
                case 'internetBanking':
                case 'prepaidIBanking':
                    this.paymentTitle = 'โมบายแบงค์กิ้ง';
                    this.paidText = this.paymentData.paid + ' บาท';
                    this.form = this.initBankForm();
                    this.error = this.initBankError();
                    break;

                case 'card':
                case 'prepaidCard':
                    this.paymentTitle = 'บัตรเครดิต - เต็มจำนวน';
                    this.paidText = this.paymentData.paid + ' บาท';
                    this.form = this.initCardForm();
                    this.error = this.initCardError();
                    break;

                case 'installment1':
                    this.paymentTitle = 'ผ่อน 0% ' + this.paymentData.period + ' เดือน';
                    this.paidText = this.paymentData.paid.replace('x', 'บาท x ');
                    this.form = this.initInstallmentForm();
                    this.error = this.initInstallmentError();
                    break;

                case 'installment2':
                    this.paymentTitle = 'ผ่อนมีดอกเบี้ย ' + this.paymentData.period + ' เดือน';
                    this.paidText = this.paymentData.paid.replace('x', 'บาท x ');
                    this.form = this.initInstallmentForm();
                    this.error = this.initInstallmentError();
                    break;
            }
        },
        initOptionMonth() {
            const option = [];

            for (let month = 1; month <= 12; month++) {
                option.push({
                    value: Helper.getLeadingZero(month, 2),
                    text: Helper.getLeadingZero(month, 2)
                });
            }

            return option;
        },
        initOptionYear() {
            const startYear = moment().year();
            const endYear = startYear + 15;
            const option = [];

            for (let year = startYear; year <= endYear; year++) {
                option.push({
                    value: Helper.getLeadingZero(year, 2).substring(2),
                    text: Helper.getLeadingZero(year, 2)
                });
            }

            return option;
        },
        initOptionMobileBanking() {
            const option = [
                { value: '004', text: 'KBANK - ธนาคารกสิกรไทย' },
                { value: '014', text: 'SCB - ธนาคารไทยพาณิชย์' },
                { value: '025', text: 'KRUNGSRI - ธนาคารกรุงศรีอยุธยา' },
                { value: '002', text: 'BBL - ธนาคารกรุงเทพ' },
                { value: '006', text: 'KTB - ธนาคารกรุงไทย' }
            ];

            return option;
        },
        initOptionInstallmentBank() {
            const option = [
                { value: '004', text: 'KBANK - ธนาคารกสิกรไทย' },
                { value: '014', text: 'SCB - ธนาคารไทยพาณิชย์' },
                { value: '011', text: 'TTB - ธนาคารทหารไทยธนชาต' },
                { value: '002', text: 'BBL - ธนาคารกรุงเทพ' },
                { value: '006', text: 'KTB - ธนาคารกรุงไทย' }
            ];

            return option;
        },
        initCardForm() {
            this.cardNo = '';
            
            return {
                cardNo: '',
                expiryMonth: '',
                expiryYear: '',
                ccv: ''
            };
        },
        initBankForm() {
            return {
                bankCode: ''
            };
        },
        initInstallmentForm() {
            return {
                bankCode: '',
                term: this.paymentData.period
            };
        },
        initCardError() {
            return {
                cardNo: false,
                expiryMonth: false,
                expiryYear: false,
                ccv: false
            };
        },
        initBankError() {
            return {
                bankCode: false
            };
        },
        initInstallmentError() {
            return {
                bankCode: false,
                term: false
            };
        },
        async initQRPayment() {
            this.qrcode = null;
            this.qrOrderId = '';

            const result = await OrderService.generateQRData();

            this.qrcode = result.data?.qrcode;
            this.qrOrderId = result.data?.orderNo;
        },
        async checkQRStatus() {
            const param = {
				id: this.qrOrderId
			};
            const result = await OrderService.getOrderDetail(param);

            if (result.data?.statusCode >= Constants.ORDER_STATUS.WAITING_CONFIRM) {
                clearInterval(this.intervalQR);

                this.$router.push(Helper.replaceRouteParam(RouterPath.RECEIPT, ':id', this.qrOrderId))
            }
        },
        submitPayment() {
            switch (this.paymentData.method) {
                case 'scanQR':
                case 'prepaidQR':
                    this.submitQrPayment();
                    break;

                case 'internetBanking':
                case 'prepaidIBanking':
                    this.submitBankPayment();
                    break;

                case 'card':
                case 'prepaidCard':
                    this.submitCardPayment();
                    break;

                case 'installment1':
                case 'installment2':
                    this.submitInstallmentPayment();
                    break;
            }
        },
        async submitCardPayment() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();
                const respToken = await OrderService.getPayCardToken(this.form);

                if (respToken.data && respToken.data.resultCode === '00') {
                    // Call charge api
                    const respCharge = await OrderService.chargeCardPayment(respToken.data.card.token, this.paymentData.method);

                    if (respCharge.data && respCharge.data.resultCode === '00') {
                        this.gbpReferenceNo = respCharge.data.gbpReferenceNo;

                        // Delay after assign gbpReferenceNo
                        setTimeout(() => {
                            GTMService.gtmBeginCheckout(this.$gtm, this.cartData.finalPrice, this.setGTMBeginCheckoutItems());
                            document.getElementById('formSecurePage').submit();
                        });
                    } else if (respCharge.data.isValid === false) {
                        loader.hide();

                        this.$swal(Helper.warningAlert('กรุณาทำรายการใหม่อีกครั้ง', respCharge.data.message)).then(() => {
                            this.$router.push(respCharge.data.redirectLink);
                        });
                    } else {
                        loader.hide();

                        this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', respCharge.data?.resultMessage));
                    }
				} else {
                    loader.hide();

					this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', respToken.data?.resultMessage));
				}
            }
        },
        async submitBankPayment() {
            if (this.validate()) {
                if (!this.$isMobile() && (this.form.bankCode === '002' || this.form.bankCode === '014')) {
                    this.$swal(Helper.warningAlert('โปรดทำการชำระเงินผ่านมือถือ', 'ขออภัย การชำระเงินด้วยโมบายแบงค์กิ้ง ต้องทำการชำระบนสมาร์ทโฟนที่ติดตั้งแอพพลิเคชั่นของธนาคารนั้นๆไว้เท่านั้น'));
                } else {
                    let loader = this.$modalLoader.render();
                    const result = await OrderService.generateBankingData(this.form.bankCode, this.paymentData.method);

                    if (result.data) {
                        if (result.data.isValid === false) {
                            loader.hide();

                            this.$swal(Helper.warningAlert('กรุณาทำรายการใหม่อีกครั้ง', result.data.message)).then(() => {
                                this.$router.push(result.data.redirectLink);
                            });
                        } else {
                            this.gbBanking = result.data;

                            setTimeout(() => {
                                GTMService.gtmBeginCheckout(this.$gtm, this.cartData.finalPrice, this.setGTMBeginCheckoutItems());
                                document.getElementById('formMobileBanking').submit();
                            });
                        }
                    } else {
                        loader.hide();

                        this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', result.data?.message));
                    }
                }
            }
        },
        async submitInstallmentPayment() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();
                const result = await OrderService.generateInstallmentData(this.form.bankCode, this.form.term, this.paymentData.method);

                if (result.data) {
                     if (result.data.isValid === false) {
                        loader.hide();

                        this.$swal(Helper.warningAlert('กรุณาทำรายการใหม่อีกครั้ง', result.data.message)).then(() => {
                            this.$router.push(result.data.redirectLink);
                        });
                    } else {
                        this.gbInstallment = result.data;

                        setTimeout(() => {
                            GTMService.gtmBeginCheckout(this.$gtm, this.cartData.finalPrice, this.setGTMBeginCheckoutItems());
                            document.getElementById('formInstallment').submit();
                        });
                    }
				} else {
                    loader.hide();

					this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', result.data?.message));
				}
            }
        },
        validate() {
            let isValid = true;
            let isPaymentBlank = false;

            // Validate Payment
            for (const property in this.error) {
                if (!this.form[property]) {
                    this.error[property] = true;
                    isValid = false;
                    isPaymentBlank = true;
                }
            }
            
            // Popup
            if (isPaymentBlank) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', 'โปรดกรอกข้อมูลการชำระเงินให้ครบ'));
            }

            return isValid;
        },
        setGTMBeginCheckoutItems() {
            const items = [];

            this.cartData.products.forEach((item) => {
                const productId = parseInt(item.param.split('.')[0]);
                const storeId = this.cartData.storeData.param.split('.')[0];
                let type = '';

                switch (this.cartData.orderType) {
                    case Constants.ORDER_TYPE.SERVICE:
                        type = 'install';
                        break;

                    case Constants.ORDER_TYPE.DELIVERY:
                        type = 'delivery';
                        break;
                }

                items.push({
                    item_id: productId,
                    item_name: item.fullName,
                    item_brand: item.brand,
                    item_category: type,
                    item_category2: item.sellerProductId,
                    item_category3: storeId,
                    discount: item.totalDiscount ? parseFloat(item.totalDiscount?.replace(/,/g, '')) : 0,
                    price: item.totalPrice ? parseFloat(item.totalPrice?.replace(/,/g, '')) : 0,
                    quantity: item.quantity
                });
            });

            return items;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-payment {
    max-width: 400px;

    .modal-body {
        padding: 0;
    }

    .top-bar {
        background: #02416d;
        display: flex;
        justify-content: space-between;
        padding: 15px;

        .title {
            display: flex;
            align-items: center;

            img {
                display: block;
                height: 50px;
            }

            .by {
                margin-left: 25px;
                color: #fff;

                h4 {
                    margin: 0;
                    font-size: 18px;
                    font-family: 'Open Sans', 'Kanit';
                    font-weight: bold;
                }

                span {
                    display: block;
                    font-size: 12px;
                    opacity: 0.7;
                }
            }
        }
    }

    .btn-close {
        color: #fff;
    }

    .qr-code {
        img {
            width: 100%;
        }

        .loading {
            position: relative;
            padding-top: 150%;
            display: flex;
            justify-content: center;

            .spinner {
                position: absolute;
                top: 45%;
            }
        }

        .download {
            padding: 10px 12px;
            background: #00427a;

            a {
                padding: 0 20px;
                height: 42px;
                font-family: 'Open Sans', 'Kanit';
                font-size: 16px;
                font-weight: bold;
                background: #2985c5;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .form {
        padding: 20px;

        .card-logo {
            display: flex;
            justify-content: center;
            padding-bottom: 7px;

            img {
                display: block;
                height: 20px;
                margin: 0 5px 5px 0;
            }
        }

        .bank-logo {
            padding-bottom: 10px;

            img {
                display: block;
                height: 33px;
            }
        }

        label {
            font-size: 13px;
            margin-bottom: 5px;
            color: #999;
        }

        input, select {
            font-size: 14px;
            background: #f5f5f5;
            color: #000;
            border: none;
            border-bottom: 1px solid #ccc;
            @include borderRadius(0);

            option {
                font-size: 13px;

                @media only screen and (max-width: $screenExtraSmall) {
                    font-size: 12px;
                }
            }
        }

        input {
            padding: 10px 15px;
        }

        select { // Fix iOS Fucking CSS
            padding: 0;
            height: 42px;
            text-indent: 15px;
        }
    }

    .operation {
        padding: 20px;

        .btn-action {
            padding: 0 20px;
            height: 52px;
            font-family: 'Open Sans', 'Kanit';
            font-size: 16px;
            font-weight: bold;
            background: #256999;
            color: #fff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            @include borderRadius(10px);
        }
    }
}
</style>