<template>
    <section class="box">
        <div class="cart-data">
            <h2><b-icon icon="tag" class="icon"></b-icon> วิธีชำระเงิน</h2>

            <div class="info">
                <div class="options">
                    <template v-if="cartData.paymentOptions.prepaid">
                        <h4 class="mt-2"><b-icon icon="chevron-right" class="icon"></b-icon> ชำระแบบมัดจำ</h4>
                        <div class="break"></div>

                        <div class="item" :class="{ 'not-allow': !cartData.paymentOptions.scanQR }">
                            <button
                                class="btn-option"
                                :class="{ 'active': paymentMethod === 'prepaidQR' }"
                                @click="openPaymentForm(cartData.paymentOptions.scanQR ? 'prepaidQR' : '', true)">
                                <div class="title">
                                    <label>มัดจำแบบสแกน QR</label>
                                    <img src="@/assets/icon-scan-qr.png" alt="มัดจำแบบสแกน QR" title="มัดจำแบบสแกน QR" />
                                </div>
                                <div class="price">
                                    <div class="wrapper">
                                        <i>{{ cartData.prepaidPrice }} บาท</i>
                                        <span>ยอดที่เหลือ {{ cartData.remainPrice }}</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="item" :class="{ 'not-allow': !cartData.paymentOptions.internetBanking }">
                            <button
                                class="btn-option"
                                :class="{ 'active': paymentMethod === 'prepaidIBanking' }"
                                @click="openPaymentForm(cartData.paymentOptions.internetBanking ? 'prepaidIBanking' : '', true)">
                                <div class="title">
                                    <label>มัดจำด้วยโมบายแบงค์กิ้ง</label>
                                    <img src="@/assets/icon-mobile-bank.png" alt="จ่ายแบบโมบายแบงค์กิ้ง" title="จ่ายแบบโมบายแบงค์กิ้ง" />
                                </div>
                                <div class="price">
                                    <div class="wrapper">
                                        <i>{{ cartData.prepaidPrice }} บาท</i>
                                        <span>ยอดที่เหลือ {{ cartData.remainPrice }}</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="item" :class="{ 'not-allow': !cartData.paymentOptions.creditCard }">
                            <button
                                class="btn-option"
                                :class="{ 'active': paymentMethod === 'prepaidCard' }"
                                @click="openPaymentForm(cartData.paymentOptions.creditCard ? 'prepaidCard' : '', true)">
                                <div class="title">
                                    <label>มัดจำด้วยบัตรเครดิต</label>
                                    <img src="@/assets/icon-credit.png" alt="จ่ายมัดจำด้วยบัตรเครดิต" title="จ่ายมัดจำด้วยบัตรเครดิต" />
                                </div>
                                <div class="price">
                                    <div class="wrapper">
                                        <i>{{ cartData.prepaidPrice }} บาท</i>
                                        <span>ยอดที่เหลือ {{ cartData.remainPrice }}</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </template>

                    <div class="break"></div>

                    <div class="note mt-4" v-if="paymentMethod === 'prepaidQR'
                                                || paymentMethod === 'prepaidIBanking'
                                                || paymentMethod === 'prepaidCard'">
                        <strong>มัดจำแล้ว กรุณาเข้ารับบริการภายในวันและช่วงเวลาที่เลือกไว้</strong>
                        <span>ค่าสินค้าและบริการส่วนที่เหลือ ชำระที่ร้านในวันติดตั้งโดยสามารถชำระเป็น เงินสด โอนชำระ หรือ บัตรเครดิต ตามที่ร้านกำหนด *กรุณาตรวจสอบเพิ่มเติม หากไม่เข้ารับบริการภายในวันที่กำหนด และไม่ได้แจ้งให้ทราบล่วงหน้า ทางบริษัท ฯ ขอสงวนสิทธิ์ไม่คืนเงินมัดจำ ในทุกกรณี</span>
                    </div>

                    <h4 class="mt-4"><b-icon icon="chevron-right" class="icon"></b-icon> ชำระเต็มจำนวน</h4>
                    <div class="break"></div>

                    <div class="item" :class="{ 'not-allow': !cartData.paymentOptions.scanQR }">
                        <button
                            class="btn-option"
                            :class="{ 'active': paymentMethod === 'scanQR' }"
                            @click="openPaymentForm(cartData.paymentOptions.scanQR ? 'scanQR' : '', false)">
                            <div class="title">
                                <label>สแกน QR</label>
                                <img src="@/assets/icon-scan-qr.png" alt="จ่ายแบบสแกน QR" title="จ่ายแบบสแกน QR" />
                            </div>
                            <div class="price">
                                <div class="wrapper">
                                    <i>{{ cartData.orderTotal }} บาท</i>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div class="item" :class="{ 'not-allow': !cartData.paymentOptions.internetBanking }">
                        <button
                            class="btn-option"
                            :class="{ 'active': paymentMethod === 'internetBanking' }"
                            @click="openPaymentForm(cartData.paymentOptions.internetBanking ? 'internetBanking' : '', false)">
                            <div class="title">
                                <label>โมบายแบงค์กิ้ง</label>
                                <img src="@/assets/icon-mobile-bank.png" alt="จ่ายแบบโมบายแบงค์กิ้ง" title="จ่ายแบบโมบายแบงค์กิ้ง" />
                            </div>
                            <div class="price">
                                <div class="wrapper">
                                    <i>{{ cartData.orderTotal }} บาท</i>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div class="item" :class="{ 'not-allow': !cartData.paymentOptions.creditCard }">
                        <button
                            class="btn-option"
                            :class="{ 'active': paymentMethod === 'card' }"
                            @click="openPaymentForm(cartData.paymentOptions.creditCard ? 'card' : '', false)">
                            <div class="title">
                                <label>บัตรเครดิต - เต็มจำนวน</label>
                                <img src="@/assets/icon-credit.png" alt="จ่ายแบบบัตรเครดิตเต็มจำนวน" title="จ่ายแบบบัตรเครดิตเต็มจำนวน" />
                            </div>
                            <div class="price">
                                <div class="wrapper">
                                    <i>{{ cartData.orderTotal }} บาท</i>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div class="break"></div>
                    <div class="item" :class="{ 'not-allow': !item.isAllow }" v-for="(item, index) in getOptionInstallment()" :key="index">
                        <button
                            class="btn-option"
                            :class="{ 'active': paymentMethod === item.method && selectedPeriod === item.period }"
                            @click="openInstallmentForm(item.isAllow, item.method, item.period, item.paid)">
                            <div class="title">
                                <label v-if="item.method === 'installment1'">ผ่อน 0% {{ item.period }} เดือน</label>
                                <label v-if="item.method === 'installment2'">ผ่อนมีดอกเบี้ย {{ item.period }} เดือน</label>
                                <label v-if="item.method === ''">ผ่อนชำระ {{ item.period }} เดือน</label>
                                <img src="@/assets/icon-installment1.png" alt="จ่ายแบบบัตรแบบผ่อน 0%" title="จ่ายแบบบัตรแบบผ่อน 0%" v-if="item.method === 'installment1'" />
                                <img src="@/assets/icon-installment2.png" alt="จ่ายแบบบัตรแบบผ่อนมีดอกเบี้ย" title="จ่ายแบบบัตรแบบผ่อนมีดอกเบี้ย" v-if="item.method === 'installment2'" />
                                <img src="@/assets/icon-credit.png" alt="จ่ายแบบบัตรแบบผ่อน" title="จ่ายแบบบัตรแบบผ่อน" v-if="item.method === ''" />
                            </div>
                            <div class="price">
                                <div class="wrapper">
                                    <i>{{ item.total }} บาท</i>
                                    <span v-if="item.paid">{{ item.paid }}</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>

                <div class="total" v-if="paymentMethod.indexOf('prepaid') === -1">
                    <label>ยอดชำระ</label>
                    <span>{{ cartData.finalPrice }}</span>
                </div>
                 <div class="total" v-else>
                    <div class="sub">
                        (<label>ยอดทั้งหมด</label>
                        <span>{{ cartData.finalPrice }}</span>)
                    </div>
                    <div>
                        <label>ยอดมัดจำ</label>
                        <span>{{ cartData.prepaidPrice }}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Constants from '@/variables/constants';
import Helper from '@/services/helper';

export default {
    data() {
        return {
            selectedPeriod: 0
        };
    },
    props: {
        cartData: Object,
        paymentMethod: String
    },
	methods: {
        getOptionInstallment() {
            const list = [];
            const periodArray = [3, 4, 6, 10];

            periodArray.forEach((period) => {
                if (this.cartData.paymentOptions.freeInstallment) {
                    list.push(this.setupInstallmentTableData(this.cartData.installmentNoAdditional, period, 'installment1'));
                } else if (this.cartData.paymentOptions.interestInstallment) {
                    list.push(this.setupInstallmentTableData(this.cartData.installmentWithAdditional, period, 'installment2'));
                } else {
                    list.push(this.setupInstallmentTableData(null, period, ''));
                }
            });

            return list;
        },
        setupInstallmentTableData(installmentData, period, method) {
            const data = installmentData?.find(x => x.period === period);

            return {
                period: period.toString(),
                paid: data ? data.paid + ' x ' + data.period + ' เดือน' : '',
                total: data ? data.total : '-',
                method: method,
                isAllow: data ? true : false
            };
        },
        validate() {
            let isValid = true;
            let isDeliveryBlank = false;
            let isScheduleBlank = false;
            let isCustomerBlank = false;
            let isTaxBlank = false;

            // Check Schedule => Customer => Payment
            if (this.cartData.orderType === Constants.ORDER_TYPE.SERVICE) {
                if (!this.cartData.appointmentDate
                || !this.cartData.appointmentTime) {
                    isValid = false;
                    isScheduleBlank = true;
                    this.$emit('validateSchedule');
                } else if (!this.cartData.customer.firstName
                    || !this.cartData.customer.lastName
                    || !this.cartData.customer.email
                    || !this.cartData.customer.tel
                    || !this.cartData.customer.carBrand
                    || !this.cartData.customer.licenseNumber
                    || !this.cartData.customer.licenseProvince) {
                    isValid = false;
                    isCustomerBlank = true;
                    this.$emit('validateCustomer');
                }
            } else if (this.cartData.orderType === Constants.ORDER_TYPE.DELIVERY) {
                if (this.cartData.orderType === Constants.ORDER_TYPE.DELIVERY
                    && (!this.cartData.shippingData.receiverName
                    || !this.cartData.shippingData.receiverTel
                    || !this.cartData.shippingData.receiverAddress)) {
                    isValid = false;
                    isDeliveryBlank = true;
                    this.$emit('validateDelivery');
                }
            }

            // Validate Tax
            if (this.cartData.customer.requireTax) {
                if (!this.cartData.customer.taxId
                    || !this.cartData.customer.taxName
                    || !this.cartData.customer.taxAddress
                    || !this.cartData.customer.taxSubDistrict
                    || !this.cartData.customer.taxDistrict
                    || !this.cartData.customer.taxProvince
                    || !this.cartData.customer.taxPostCode) {
                    isValid = false;
                    isTaxBlank = true;
                    this.$emit('validateTax');
                }
            }
            
            // Popup
            if (isDeliveryBlank) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', 'โปรดกรอกข้อมูลการจัดส่งให้ครบ'));
            } else if (isScheduleBlank) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', 'โปรดเลือกวันและเวลาเข้ารับบริการ'));
            } else if (isCustomerBlank) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', 'โปรดทำการบันทึกข้อมูลลูกค้า'));
            } else if (isTaxBlank) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', 'โปรดกรอกข้อมูลใบกำกับภาษีให้ครบถ้วน'));
            }

            return isValid;
        },
        openPaymentForm(method, isPrepaid) {
            if (method) {
                if (this.validate()) {
                    this.$emit('changePaymentMethod', {
                        method: method,
                        installmentPeriod: 0,
                        paid: isPrepaid ? this.cartData.prepaidPrice : this.cartData.orderTotal,
                    });
                }
            }
        },
        openInstallmentForm(isAllow, method, period, paid) {
            if (isAllow) {
                if (this.validate()) {
                    this.selectedPeriod = period;

                    this.$emit('changePaymentMethod', {
                        method: method,
                        installmentPeriod: period,
                        paid: paid,
                    });
                }
            }
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';
@import '@/components/order/style/Cart.scss';

$screenSpecialSmall: 480px;

.options {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .item {
        @media only screen and (max-width: $screenSpecialSmall) {
            width: 100%;
        }

        &.not-allow {
            opacity: 0.4;

            .btn-option {
                cursor: not-allowed;

                &:hover {
                    opacity: 1;
                    background: none;
                    border: 1px solid #ccc;
                }
            }
        }
    }

    .break {
        flex-basis: 100%;
        height: 0;
    }

    .note {
        background: #f7e6e6;
        padding: 15px;
        font-size: 13px;
        
        strong, span {
            display: block;
        }
    }

    h4 {
        display: block;
        margin-bottom: 13px;
        font-size: 15px;
        font-weight: bold;
        color: #333;

        .icon {
            margin-right: 3px;
        }
    }

    .btn-option {
        width: 200px;
        margin: 0 7px 7px 0;
        padding: 5px;
        border: 1px solid #ccc;
        background: none;
        text-align: center;
        @include borderRadius(5px);

        @media only screen and (max-width: $screenSpecialSmall) {
            width: 100%;
            margin: 0 0 5px;
            display: flex;
            align-items: center;
        }

        &.active, &:hover {
            opacity: 1;
            background: #f2f2f2;
            border: 1px solid #333;
            color: #000;
        }

        .title {
            @media only screen and (max-width: $screenSpecialSmall) {
                width: 50%;
            }

            label {
                display: block;
                font-size: 13px;
                margin-top: 4px;
                color: #555;

                @media only screen and (max-width: $screenExtraSmall) {
                    font-size: 11px;
                }
            }

            img {
                display: block;
                height: 48px;
                margin: 5px auto;
            }
        }

        .price {
            display: flex;
            height: 44px;
            align-items: center;

            @media only screen and (max-width: $screenSpecialSmall) {
                width: 50%;
            }

            .wrapper {
                width: 100%;

                i {
                    display: block;
                    width: 100%;
                    color: #e37500;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 18px;
                }

                span {
                    display: block;
                    width: 100%;
                    color: #555;
                    font-size: 12px;
                }
            }
        }
    }
}

.no-data {
    .bi {
        font-size: 80px;
        color: #ccc;
    }
}

.total {
    border-top: 1px dashed #ddd;
    font-size: 24px;
    font-weight: bold;
    text-align: right;
    padding-top: 15px;

    @media only screen and (max-width: $screenExtraSmall) {
        display: flex;
        justify-content: space-between;
    }

    label {
        margin: 0;
        margin-right: 10px;
        text-align: left;
    }

    span {
        color: #cc0000;

        &:before {
            content: "฿";
            margin-right: 5px;
            color: #333;
            font-weight: normal;
        }
    }

    .sub {
        font-size: 15px;
        font-weight: normal;

        label {
            width: auto;
        }
    }
}
</style>