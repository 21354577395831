<template>
    <div class="order-step">
        <div class="item">
            <div class="dot" :class="{ 'active': step == 1, 'pass': step > 1 }">
                <b-icon icon="check" class="icon" v-if="step > 1"></b-icon>
            </div>
            <span>เลือกสินค้า</span>
        </div>
        <div class="item">
            <div class="dot" :class="{ 'active': step == 2, 'pass': step > 2 }">
                <b-icon icon="check" class="icon" v-if="step > 2"></b-icon>
            </div>
            <span>เลือกร้าน<br />จัดส่ง/ติดตั้ง</span>
        </div>
        <div class="item" v-if="!isUserLogin()">
            <div class="dot" :class="{ 'active': step == 3, 'pass': step > 3 }">
                <b-icon icon="check" class="icon" v-if="step > 3"></b-icon>
            </div>
            <span>ลงชื่อเข้าสู่ระบบ</span>
        </div>
        <div class="item">
            <div class="dot" :class="{ 'active': step == 4, 'pass': step > 4 }">
                <b-icon icon="check" class="icon" v-if="step > 4"></b-icon>
            </div>
            <span>กรอกข้อมูล<br />ลูกค้า/รถ</span>
        </div>
        <div class="item">
            <div class="dot" :class="{ 'active': step == 5, 'pass': step > 5 }">
                <b-icon icon="check" class="icon" v-if="step > 5"></b-icon>
            </div>
            <span>ชำระเงิน</span>
        </div>
        <div class="item">
            <div class="dot" :class="{ 'active': step == 6, 'pass': step > 6 }">
                <b-icon icon="check" class="icon" v-if="step > 6"></b-icon>
            </div>
            <span>รอรับ/ติดตั้ง<br />สินค้า</span>
        </div>
    </div>
</template>

<script>
import UserService from '@/services/userService';

export default {
    props: {
        step: Number
    },
    methods: {
        isUserLogin() {
            return UserService.isUserLogin();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.order-step {
    position: relative;
    z-index: 0;
    overflow: hidden;
    display: flex;

    .item {
        width: 25%;
        position: relative;
        z-index: 1;
        
        .dot {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 5px;
            width: 30px;
            height: 30px;
            background: #ddd;
            @include borderRadius(1000px);

            &.active {
                background: #d5aa06;
                border: 10px solid #ffd505;
            }

            &.pass {
                background: #ff9b00;
            }
        }

        span {
            display: block;
            margin: auto;
            text-align: center;
            font-size: 11px;
            line-height: 14px;
            max-width: 100px;
            color: #333;
        }
    }

    &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 14px;
        left: 0;
        right: 0;
        z-index: 0;
        height: 1px;
        background: #ccc;
    }
}
</style>