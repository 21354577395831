<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-promotion-info modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>ข้อมูลโปรโมชั่น</span>
                        </h4>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div v-html="promotionData.detail"></div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
export default {
    data() {
		return {
            isActive: false
		};
	},
	props: {
		isDisplay: Boolean,
        promotionData: Object
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide() {
            this.$emit('close');
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-promotion-info {
    max-width: 600px;
}
</style>