<template>
    <section class="box">
        <div class="cart-data">
            <div class="decoration"></div>

            <h2><b-icon icon="truck" class="icon"></b-icon> ข้อมูลการจัดส่ง</h2>
            
            <div class="info">
                <template v-if="isDeliveryReady">
                    <div class="selected-data">
                        <label class="name">{{ cartData.shippingData.receiverName }}</label>
                    </div>
                    <div class="selected-data">
                        <label class="me-3"><b-icon icon="telephone" class="icon"></b-icon> {{ cartData.shippingData.receiverTel }}</label>
                        <label><b-icon icon="envelope" class="icon"></b-icon> {{ cartData.customer.email }}</label>
                    </div>
                    <div class="selected-data">
                        <label><b-icon icon="geo" class="icon"></b-icon> {{ cartData.shippingData.receiverAddress }}</label>
                    </div>
                    <div class="selected-data" v-if="cartData.shippingData.deliveryRemark">
                        <label><b-icon icon="bell" class="icon"></b-icon> {{ cartData.shippingData.deliveryRemark }}</label>
                    </div>
                    <button class="btn btn-warning btn-edit" @click="editInfo()">แก้ไข</button>
                </template>

                <div class="form" v-if="form && !isDeliveryReady">
                    <div class="customer">
                        <div class="row">
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>ชื่อผู้รับ</label>
                                    <input type="text"
                                        v-model="form.firstName"
                                        maxlength="100"
                                        placeholder="ชื่อลูกค้า"
                                        :class="{ 'invalid': error.firstName }" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>นามสกุลผู่รับ</label>
                                    <input type="text"
                                        v-model="form.lastName"
                                        maxlength="100"
                                        placeholder="นามสกุลลูกค้า"
                                        :class="{ 'invalid': error.lastName }" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>เบอร์ติดต่อผู้รับ</label>
                                    <input type="tel"
                                        @keypress="$AppUtility.inputOnlyNumber($event)"
                                        v-model="form.tel"
                                        maxlength="20"
                                        placeholder="081XXXXXXX"
                                        :class="{ 'invalid': error.tel }" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>อีเมลผู้รับ</label>
                                    <input type="text"
                                        v-model="form.email"
                                        maxlength="100"
                                        placeholder="email@website.com"
                                        :class="{ 'invalid': error.email }" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-12 col-sm-6">
                                <div class="input-field">
                                    <label>ที่อยู่จัดส่ง</label>
                                    <input type="text"
                                        v-model="form.address"
                                        maxlength="100"
                                        placeholder="บ้านเลขที่/หมู่บ้าน/ซอย/ถนน"
                                        :class="{ 'invalid': error.address }" />
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>ตำบล/แขวง</label>
                                    <address-auto-complete
                                        ref="thaiAddressDataTumbon"
                                        placeholder="ตำบล/แขวง"
                                        :listData="mixinsThaiAddressList"
                                        v-model="form.subDistrict"
                                        :inputClass="[(error.subDistrict ? 'invalid' : '')]"
                                        @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                        @input="mixinsThaiAddressSetAutoComplete($event, 'tumbon')"
                                        @blur="mixinsThaiAddressCheckValidInputAddress($event, 'tumbon')" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>อำเภอ/เขต</label>
                                    <address-auto-complete
                                        ref="thaiAddressDataAmphur"
                                        placeholder="อำเภอ/เขต"
                                        :listData="mixinsThaiAddressList"
                                        v-model="form.district"
                                        :inputClass="[(error.district ? 'invalid' : '')]"
                                        @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                        @input="mixinsThaiAddressSetAutoComplete($event, 'amphur')"
                                        @blur="mixinsThaiAddressCheckValidInputAddress($event, 'amphur')" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>จังหวัด</label>
                                    <address-auto-complete
                                        ref="thaiAddressDataProvince"
                                        placeholder="จังหวัด"
                                        :listData="mixinsThaiAddressList"
                                        v-model="form.province"
                                        :inputClass="[(error.province ? 'invalid' : '')]"
                                        @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                        @input="mixinsThaiAddressSetAutoComplete($event, 'province')"
                                        @blur="mixinsThaiAddressCheckValidInputAddress($event, 'province')" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>รหัสไปรษณีย์</label>
                                    <address-auto-complete
                                        ref="thaiAddressDataPostCode"
                                        placeholder="รหัสไปรษณีย์"
                                        :listData="mixinsThaiAddressList"
                                        v-model="form.postCode"
                                        :inputClass="[(error.postCode ? 'invalid' : '')]"
                                        @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                        @input="mixinsThaiAddressSetAutoComplete($event, 'postcode')"
                                        @blur="mixinsThaiAddressCheckValidInputAddress($event, 'postcode')" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-12 col-sm-6">
                                <div class="input-field">
                                    <label>หมายเหตุการจัดส่ง</label>
                                    <input type="text"
                                        v-model="form.deliveryRemark"
                                        maxlength="200"
                                        placeholder="หมายเหตุการจัดส่ง" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center mt-3">
                        <button class="btn btn-warning btn-save-info" @click="submitDeliveryInfo()">บันทึกข้อมูลจัดส่ง</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ThaiAddress from '@/mixins/thaiAddress';
import Helper from '@/services/helper';
import OrderService from '@/services/orderService';

export default {
    mixins: [ ThaiAddress ],
	data() {
		return {
            isDeliveryReady: false,
            form: null,
            error: this.initError()
		};
	},
    props: {
		cartData: Object
	},
    async mounted() {
        this.isDeliveryReady = this.checkDeliveryDataReady();
        this.form = this.initForm();
    },
	methods: {
        checkDeliveryDataReady() {
            if (this.cartData) {
                if (this.cartData.shippingData.receiverFirstname
                    && this.cartData.shippingData.receiverLastname
                    && this.cartData.shippingData.receiverTel
                    && this.cartData.shippingData.receiverAddress
                    && this.cartData.customer.email) {
                    return true;
                }
            }

            return false;
        },
        initForm() {
            let initSubDistrict = '';
            let initDistrict = '';
            let initProvince = '';
            let initPostCode = '';

            if (localStorage.deliveryArea) {
                const arrayDelivery = decodeURIComponent(window.atob(localStorage.deliveryArea).split(' » '));
                
                initSubDistrict = arrayDelivery[0];
                initDistrict = arrayDelivery[1];
                initProvince = arrayDelivery[2];
                initPostCode = arrayDelivery[3];
            }

            return {
                firstName: this.cartData ? this.cartData.shippingData.receiverFirstname : '',
                lastName: this.cartData ? this.cartData.shippingData.receiverLastname : '',
                tel: this.cartData ? this.cartData.shippingData.receiverTel : '',
                email: this.cartData ? this.cartData.customer.email : '',
                address: this.cartData ? this.cartData.customer.address : '',
                subDistrict: this.cartData ? this.cartData.customer.subDistrict : initSubDistrict,
                district: this.cartData ? this.cartData.customer.district : initDistrict,
                province: this.cartData ? this.cartData.customer.province : initProvince,
                postCode: this.cartData ? this.cartData.customer.postCode : initPostCode,
                deliveryRemark: this.cartData ? this.cartData.shippingData.deliveryRemark : '',
            };
        },
        initError() {
            return {
                firstName: false,
                lastName: false,
                tel: false,
                email: false,
                address: false,
                subDistrict: false,
                district: false,
                province: false,
                postCode: false
            };
        },
        editInfo() {
            this.isDeliveryReady = false;
        },
        async submitDeliveryInfo() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();
                const result = await OrderService.submitDeliveryInfo(this.form);
                loader.hide();

                if (result.data?.success) {
                    this.isDeliveryReady = true;
                    this.$emit('doneSaveDeliveryInfo');
				} else {
					this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', result.data?.message));
				}
            }
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            for (const property in this.error) {
                if (!this.form[property]?.trim()) {
                    this.error[property] = true;
                    isValid = false;
                    isBlank = true;
                }
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';
@import '@/components/order/style/Cart.scss';
@import '@/components/order/style/CustomerForm.scss';

.box {
    border-top: none;

    .cart-data {
        padding-top: 25px;

        .decoration {
            @extend .deliveryDecoration;
        }
    }
}
</style>