<template>
    <section class="box" v-if="form">
        <div class="cart-data">
            <h2><b-icon icon="shop" class="icon"></b-icon> ร้านค้า</h2>

            <div class="info">
                <div class="store">
                    <router-link :to="routerPath.STORE + '/' + cartData.storeData.param" class="picture">
                        <img :src="cartData.storeData.picture" :title="cartData.storeData.name" :alt="cartData.storeData.name" @error="helper.setNoImg($event, 'store')" />
                    </router-link>

                    <div class="detail">
                        <h3><b-icon icon="shop-window" class="icon"></b-icon> {{ cartData.storeData.name }}</h3>

                        <address>
                            <p>{{ cartData.storeData.address }}</p>
                            <p>โทร. {{ cartData.storeData.tel }}</p>
                            <div class="opening-time">
                                <label>เปิดบริการ</label><span v-html="cartData.storeData.openTime"></span>
                            </div>
                        </address>

                        <div class="schedule mt-2" v-if="cartData.orderType === constants.ORDER_TYPE.SERVICE">
                            <label>เลือกวันติดตั้ง</label>
                            <div class="input-date-icon">
                                <datepicker
                                    @input="selectScheduleSlot(true, true)"
                                    v-model="form.scheduleDate"
                                    :disabled-dates="getDisableDate()"
                                    :input-class="[ 'input-date', (error.scheduleDate ? 'invalid' : '') ]"
                                    :language="calendarTH"
                                    :full-month-name="true"
                                    calendar-class="datepicker-box"
                                    format="d/M/yyyy">
                                </datepicker>
                                <b-icon icon="calendar"></b-icon>
                            </div>
                        </div>

                        <div class="schedule mt-3" v-if="cartData.orderType === constants.ORDER_TYPE.SERVICE">
                            <label>เวลานัดหมาย</label>
                            <loader-spinner
                                v-if="isLoadScheduleTime"
                                class="schedule-loader"
                                sizeWidth="25px"
                                sizeHeight="25px">
                            </loader-spinner>
                            <span class="not-select" v-if="scheduleList.length === 0">โปรดเลือกวันติดตั้ง</span>
                            <b-form-group v-if="!isLoadScheduleTime">
                                <b-form-radio 
                                    v-for="(item, index) in scheduleList"
                                    :key="index"
                                    v-model="form.scheduleTime" 
                                    name="appointment-time" 
                                    :value="item.value"
                                    @change="selectScheduleSlot(true, false)">
                                    ช่วงเวลา ตั้งแต่ {{ item.from }} - {{ item.to }}
                                </b-form-radio>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { th } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import Constants from '@/variables/constants';
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import OrderService from '@/services/orderService';
import LoaderSpinner from '@/components/utility/LoaderSpinner';

export default {
    components: {
        LoaderSpinner
    },
    data() {
        return {
            constants: Constants,
            helper: Helper,
            routerPath: RouterPath,
            calendarTH: th,
            form: null,
            error: this.initError(),
            scheduleList: [],
            isLoadScheduleTime: false
        }
    },
	props: {
		cartData: Object
	},
    watch: {
        cartData: {
            handler: function () {
                this.reloadForm();
            },
            deep:true
        },
    },
    mounted() {
        this.form = this.initForm();
        this.selectScheduleSlot(false, true);
    },
	methods: {
        initForm() {
            return {
                scheduleDate: this.cartData ? this.cartData.appointmentDate : null,
                scheduleTime: this.cartData ? this.cartData.appointmentTime : null
            };
        },
        initError() {
            return {
                scheduleDate: false,
                scheduleTime: false
            };
        },
        reloadForm() {
            this.form = this.initForm();
        },
        async selectScheduleSlot(isSubmit, isShowLoader) {
            if (this.form.scheduleDate) {
                this.isLoadScheduleTime = isShowLoader;
                const result = await OrderService.getServiceSlot(this.form.scheduleDate);

                if (result.data?.scheduleList.length > 0) {
                    this.scheduleList = result.data.scheduleList;

                    if (isSubmit) {
                        this.submitSchedule();
                    }
				} else {
					this.$swal(Helper.warningAlert('ไม่พบช่วงเวลาให้บริการ', 'ไม่มีช่องว่างสำหรับจองเข้ารับบริการในวันที่ที่เลือกไว้'));
				}

                this.isLoadScheduleTime = false;
            }
        },
        getDisableDate() {
            const blockDateTo = moment(this.cartData.startDate, 'YYYY-MM-DD').toDate();
            const blockDateFrom = moment(this.cartData.startDate, 'YYYY-MM-DD').add(30, 'days').toDate();

            return {
                to: blockDateTo,
                from: blockDateFrom
            };
        },
        async submitSchedule() {
            this.error = this.initError();
            const result = await OrderService.submitSchedule(this.form);

            if (!result.data?.success) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', result.data?.message));
            }

            this.$emit('doneSaveSchedule');
        },
        validate() {
            if (!this.form.scheduleDate) {
                this.error.scheduleDate = true;
            }
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';
@import '@/components/order/style/Cart.scss';

.store {
    display: flex;

    @media only screen and (max-width: $screenExtraSmall) {
        display: block;
    }

    .picture {
        display: block;
        width: 250px;
        margin-right: 30px;

        @media only screen and (max-width: $screenMedium) {
            width: 180px;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            margin: 0 auto 15px;
            width: 100%;
            max-width: 300px;
        }

        img {
            display: block;
            width: 100%;
            border: 1px solid #ccc;
        }

        a {
            border: 1px solid #ccc;
            background: none;
            color: #999;
            display: block;
            font-size: 14px;
            margin-top: 10px;
            padding: 6px;
            text-align: center;

            @media only screen and (max-width: $screenExtraSmall) {
                padding: 4px;
            }
        }
    }

    .detail {
        flex: 1;
    }
}

h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    padding-bottom: 7px;
    margin-bottom: 10px;
    border-bottom: 1px dashed #ddd;

    .icon {
        opacity: 0.5;
        margin-right: 5px;
    }
}

address {
    margin: 0;
    font-size: 13px;

    p {
        margin: 0;
    }

    .opening-time {
        display: flex;

        label {
            margin: 0;
            margin-right: 10px;
        }
    }
}

.schedule {
    label {
        display: block;
        font-size: 15px;
        font-weight: bold;
        color: #d78c1b;
        margin-bottom: 5px;
    }

    .not-select {
        font-size: 13px;
        color: #777;
    }
}

.schedule-loader {
    padding: 40px 20px;
    text-align: left;
}

::v-deep .input-date-icon {
    width: 40%;
    min-width: 280px;

    .input-date {
        height: 40px;
        font-size: 14px;
        padding: 0 20px;
    }

    .bi {
        font-size: 20px;
    }
}

::v-deep .schedule {
    .custom-radio {
        margin-bottom: 3px;

        .custom-control-label {
            font-size: 13px;
        }
    }
}
</style>