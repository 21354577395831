<template>
    <section class="box">
        <div class="cart-data">
            <h2><b-icon icon="person" class="icon"></b-icon> ข้อมูลลูกค้า</h2>
            
            <div class="info">
                <div class="form" v-if="formCustomer">
                    <template v-if="isCustomerReady">
                        <div class="selected-data">
                            <label class="name">{{ cartData.customer.firstName }} {{ cartData.customer.lastName }}</label>
                        </div>
                        <div class="selected-data">
                            <label class="me-3"><b-icon icon="telephone" class="icon"></b-icon> {{ cartData.customer.tel }}</label>
                            <label><b-icon icon="envelope" class="icon"></b-icon> {{ cartData.customer.email }}</label>
                        </div>
                        <div class="selected-data">
                            <label><b-icon icon="minecart" class="icon"></b-icon> {{ cartData.customer.carBrand }} {{ cartData.customer.carModel }} | {{ cartData.customer.licenseNumber }} {{ cartData.customer.licenseProvince }}</label>
                        </div>

                        <button class="btn btn-warning btn-edit" @click="editInfo()" v-if="isCustomerReady">แก้ไข</button>
                    </template>

                    <div class="customer" v-if="!isCustomerReady">
                        <div class="row">
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>ชื่อลูกค้า</label>
                                    <input type="text"
                                        v-model="formCustomer.firstName"
                                        maxlength="100"
                                        placeholder="ชื่อลูกค้า"
                                        :class="{ 'invalid': errorCustomer.firstName }" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>นามสกุลลูกค้า</label>
                                    <input type="text"
                                        v-model="formCustomer.lastName"
                                        maxlength="100"
                                        placeholder="นามสกุลลูกค้า"
                                        :class="{ 'invalid': errorCustomer.lastName }" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>อีเมล</label>
                                    <input type="text"
                                        v-model="formCustomer.email"
                                        maxlength="100"
                                        placeholder="อีเมล"
                                        :class="{ 'invalid': errorCustomer.email }" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-lg-3">
                                <div class="input-field">
                                    <label>โทร</label>
                                    <input type="tel"
                                        @keypress="$AppUtility.inputOnlyNumber($event)"
                                        v-model="formCustomer.tel"
                                        maxlength="20"
                                        placeholder="เบอร์ติดต่อ"
                                        :class="{ 'invalid': errorCustomer.tel }" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-12 col-sm-6">
                                <div class="input-field">
                                    <label>ข้อมูลรถยนต์</label>
                                    <dropdown
                                        v-model="selectedCar"
                                        placeholder="-- เลือกรถของคุณ --"
                                        :options="option.myCar"
                                        :classes="{ input: [errorCar.myCar ? 'invalid' : ''] }">
                                    </dropdown>
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6">
                                <div class="input-field">
                                    <label class="d-none d-sm-block">&nbsp;</label>
                                    <button class="btn btn-outline-secondary btn-add-car" @click="openCarModal()">+ เพิ่มข้อมูลรถ</button>
                                </div>
                            </div>
                        </div>

                        <div class="text-center mt-3">
                            <button class="btn btn-danger btn-save-info" @click="submitCustomer()">บันทึกข้อมูลลูกค้า</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-car @close="closeCarModal($event)" :isDisplay="isOpenCarModal"></modal-car>
    </section>
</template>

<script>
import Constants from '@/variables/constants';
import ModalCar from '@/components/user/ModalCar.vue';
import Helper from '@/services/helper';
import OrderService from '@/services/orderService';
import UserService from '@/services/userService';

export default {
    components: { ModalCar },
	data() {
		return {
            constants: Constants,
            isCustomerReady: false,
            formCustomer: null,
            errorCustomer: this.initErrorCustomer(),
            errorCar: this.initErrorCar(),
            option: {
                myCar: []
            },
            selectedCar: null,
            isOpenCarModal: false
		};
	},
    props: {
		cartData: Object
	},
    async mounted() {
        await this.loadMyCarOption();
        this.isCustomerReady = this.checkCustomerReady();
        this.formCustomer = this.initFormCustomer();

        if (this.cartData) {
            this.selectedCar = this.serializeCarData(
                this.cartData.customer.licenseNumber,
                this.cartData.customer.licenseProvince,
                this.cartData.customer.carBrand,
                this.cartData.customer.carModel
            );
        } else if (this.option.myCar.length > 0) {
            const selectedCar = JSON.parse(this.option.myCar[0]);

            this.selectedCar = this.serializeCarData(
                selectedCar.licenseNumber,
                selectedCar.licenseProvince,
                selectedCar.carBrand,
                selectedCar.carModel
            );
        }
    },
	methods: {
        checkCustomerReady() {
            if (this.cartData) {
                if (this.cartData.customer.firstName?.trim()
                    && this.cartData.customer.lastName?.trim()
                    && this.cartData.customer.email?.trim()
                    && this.cartData.customer.tel?.trim()
                    && this.cartData.customer.licenseNumber?.trim()
                    && this.cartData.customer.licenseProvince?.trim()) {
                    return true;
                }
            }

            return false;
        },
        initFormCustomer() {
            return {
                firstName: this.cartData ? this.cartData.customer.firstName : '',
                lastName: this.cartData ? this.cartData.customer.lastName : '',
                email: this.cartData ? this.cartData.customer.email : '',
                tel: this.cartData ? this.cartData.customer.tel : '',
                licenseNumber: '',
                licenseProvince: '',
                carBrand: '',
                carModel: ''
            };
        },
        initErrorCustomer() {
            return {
                firstName: false,
                lastName: false,
                email: false,
                tel: false
            };
        },
        initErrorCar() {
            return {
                myCar: false
            };
        },
        editInfo() {
            this.isCustomerReady = false;
        },
        serializeCarData(licenseNumber, licenseProvince, carBrand, carModel) {
            return JSON.stringify({
                licenseNumber: licenseNumber,
                licenseProvince: licenseProvince,
                carBrand: carBrand,
                carModel: carModel
            });
        },
        async loadMyCarOption() {
            let loader = this.$modalLoader.render();
            const result = await UserService.getCar();
            loader.hide();

            this.option.myCar = [];

            if (result.data) {
                result.data.forEach((item) => {
                    const carKeyId = this.serializeCarData(
                        item.licenseNumber,
                        item.licenseProvince,
                        item.carBrand,
                        item.carModel
                    );

                    this.option.myCar.push({
                        id: carKeyId,
                        name: [ item.licenseNumber, '-', item.carBrand, item.carModel ].join(' ')
                    });
                });
            } else {
                this.$swal(Helper.warningAlert('เกิดข้อผิดพลาดในการเชื่อมต่อระบบ', 'ดูเหมือนเกิดความขัดข้องในระหว่างการเชื่อมต่อข้อมูล โปรดทำการโหลดหน้าใหม่อีกครั้ง'))
                    .then(() => {
                        location.reload();
                    });
            }
        },
        async submitCustomer() {
            if (this.validate()) {
                // Preare form data
                this.prepareCarData();

                let loader = this.$modalLoader.render();
                const result = await OrderService.submitCustomer(this.formCustomer);
                loader.hide();

                if (result.data?.success) {
                    this.isCustomerReady = true;
                    this.$emit('doneSaveCustomer');
				} else {
					this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', result.data?.message));
				}
            }
        },
        prepareCarData() {
            const selectedCar = JSON.parse(this.selectedCar);

            this.formCustomer.licenseNumber = selectedCar.licenseNumber;
            this.formCustomer.licenseProvince = selectedCar.licenseProvince;
            this.formCustomer.carBrand = selectedCar.carBrand;
            this.formCustomer.carModel = selectedCar.carModel;
        },
        validate() {
            this.errorCustomer = this.initErrorCustomer();
            this.errorCar = this.initErrorCar();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            for (const property in this.errorCustomer) {
                if (!this.formCustomer[property]?.trim()) {
                    this.errorCustomer[property] = true;
                    isValid = false;
                    isBlank = true;
                }
            }

            // Check Select Car
            if (!this.selectedCar) {
                this.errorCar.myCar = true;
                isValid = false;
                isBlank = true;
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', 'โปรดกรอกข้อมูลลูกค้าให้ครบถ้วน'));
            }

            return isValid;
        },
        openCarModal() {
            this.isOpenCarModal = true;
        },
        async closeCarModal(data) {
            this.isOpenCarModal = false;

            if (data.isReload) {
                const carKeyId = this.serializeCarData(
                    data.car.licenseNumber,
                    data.car.licenseProvince,
                    data.car.carBrand,
                    data.car.carModel
                );

                this.option.myCar.push({
                    id: carKeyId,
                    name: [ data.car.licenseNumber, '-', data.car.carBrand, data.car.carModel ].join(' ')
                });

                this.selectedCar = carKeyId;
            }
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/order/style/Cart.scss';
@import '@/components/order/style/CustomerForm.scss';
</style>