<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-additional-size modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>เพิ่มยางขนาดอื่นในตะกร้า</span>
                        </h4>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="product-data">
                            <div class="product-selection">
                                <div class="picture d-none d-lg-block">
                                    <img :src="product.picture" 
                                        :title="product.brand"
                                        :alt="product.brand"
                                        @error="helper.setNoImg($event, 'product')" />
                                </div>
                                <div class="info">
                                    <img :src="product.brandLogo" 
                                        :title="product.brand"
                                        :alt="product.brand"
                                        class="brand"
                                        @error="helper.setNoImg($event, 'brand')" />

                                    <div class="store">
                                        <label>ร้านค้า</label>
                                        <div class="name">{{ store.name }}</div>
                                        <address>
                                            <p>{{ store.address }}</p>
                                            <p>โทร. {{ store.tel }}</p>
                                        </address>
                                    </div>
                                </div>
                            </div>

                            <div class="loading" v-if="!isSizeReady">
                                <loader-content :isDisplay="true"></loader-content>
                            </div>

                            <div class="list" v-if="isSizeReady">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>รุ่น/ขนาด</th>
                                            <th>ราคา</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in sizeList.products" :key="index">
                                            <td>รุ่น {{ item.model }} ขนาด {{ item.size }} (ปี {{ item.year }})</td>
                                            <td>{{ item.price }} -.</td>
                                            <td class="text-end"><button class="btn btn-sm btn-danger" @click="chooseItem(item)">เลือก</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import ProductService from '@/services/productService';

export default {
    data() {
		return {
            isActive: false,
            isSizeReady: false,
            sizeList: []
		};
	},
	props: {
		isDisplay: Boolean,
        product: Object,
        store: Object
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);

            if (value) {
                this.getRelatedProductSize();
            }
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide() {
            this.$emit('close');
        },
        async getRelatedProductSize() {
            this.isSizeReady = false;

            const param = {
                sellerProductId: this.product.sellerProductId
            };
            const result = await ProductService.getProductSizeByCriteria(param);

            if (result.data) {
                this.sizeList = result.data;
            }

            this.isSizeReady = true;
        },
        chooseItem(item) {
            this.$emit('chooseSize', item);
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-additional-size {
    max-width: 800px;

    .loading {
        height: 200px;
    }

    .product-data {
        .product-selection {
            margin-bottom: 15px;
            display: flex;

            .picture {
                position: relative;
                overflow: hidden;
                width: 195px;
                height: 80px;

                img {
                    position: absolute;
                    top: -20%;
                    width: 100%;
                }
            }

            .info {
                flex: 1;
                display: flex;
                align-items: center;

                @media only screen and (max-width: $screenExtraSmall) {
                    display: block;
                    text-align: center;
                }

                .brand {
                    height: 80px;
                    border: 1px solid #bbb;

                    @media only screen and (max-width: $screenSmall) {
                        height: 60px;
                    }

                    @media only screen and (max-width: $screenExtraSmall) {
                        height: 45px;
                    }
                }

                .store {
                    flex: 1;
                    padding-left: 15px;

                    label {
                        display: block;
                        font-size: 13px;
                        color: #b32f2f;
                        margin-bottom: 5px;
                        padding-bottom: 2px;
                        border-bottom: 1px solid #ddd;

                        @media only screen and (max-width: $screenSmall) {
                            font-size: 11px;
                        }

                        @media only screen and (max-width: $screenExtraSmall) {
                            margin-top: 10px;
                        }
                    }

                    .name {
                        display: block;
                        font-size: 14px;
                        font-weight: bold;
                        color: #555;
                        margin: 0 0 3px;
                    }

                    address {
                        display: block;
                        font-size: 12px;
                        line-height: 15px;
                        color: #888;
                        margin: 0;

                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .list {
            margin-top: 20px;
            border-top: 1px dashed #999;

            table {
                th {
                    background: #eee;
                }

                th, td {
                    font-size: 13px;
                }

                .btn {
                    font-size: 13px;
                }
            }
        }
    }
}
</style>