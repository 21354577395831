export default  {
    data() {
        return {
            mixinsThaiAddressDataIndex: { 
                tumbon: 0,
                amphur: 1,
                province: 2,
                postcode: 3
            },
            mixinsThaiAddressList: [],
            mixinsThaiAddressSeparator: ' » '
        } 
    },
    mounted() {
        this.$store.dispatch('fetchThaiAddressData');
    },
    methods: {
        mixinsThaiFullAddressSetAutoComplete(typing) {
            this.mixinsThaiAddressList = this.mixinsThaiFullAddressGetAutoCompleteAddress(typing);
        },
        mixinsThaiFullAddressGetAutoCompleteAddress(typing) {
            const jsonThaiAddress = this.$store.getters.getThaiAddressData;
            const filteredData = jsonThaiAddress.filter(item => {
                return (
                    item.province.startsWith(typing) ||
                    item.listAmphur.some(amphur =>
                        amphur.amphur.startsWith(typing) ||
                        amphur.listTumbon.some(tumbon =>
                        tumbon.tumbon.startsWith(typing) ||
                        tumbon.postcode.startsWith(typing)
                        )
                    )
                );
            });

            const formattedData = [];

            filteredData.forEach(item => {
                item.listAmphur.forEach(amphur => {
                    amphur.listTumbon.forEach(tumbon => {
                        const formattedString = `${tumbon.tumbon}${this.mixinsThaiAddressSeparator}${amphur.amphur}${this.mixinsThaiAddressSeparator}${item.province}${this.mixinsThaiAddressSeparator}${tumbon.postcode}`;
                        formattedData.push(formattedString);
                    });
                });
            });

            return formattedData;
        },
        mixinsThaiAddressSetAutoComplete(typing, selector) {
            this.mixinsThaiAddressList = this.mixinsThaiAddressGetAutoCompleteAddress(typing, selector);
        },
        mixinsThaiAddressGetAutoCompleteAddress(typing, selector) {
            const items = [];
            const jsonThaiAddress = this.$store.getters.getThaiAddressData;

            jsonThaiAddress.forEach((valueProvince) => {
                if (selector != 'province' || valueProvince.province.indexOf(typing) === 0) {
                    valueProvince.listAmphur.forEach((valueAmphur) => {
                        if (selector != 'amphur' || valueAmphur.amphur.indexOf(typing) === 0) {
                            valueAmphur.listTumbon.forEach((valueTumbon) => {
                                if ((selector != 'tumbon' || valueTumbon.tumbon.indexOf(typing) === 0) && (selector != 'postcode' || valueTumbon.postcode.indexOf(typing) === 0)) {
                                    items.push(valueTumbon.tumbon + this.mixinsThaiAddressSeparator + valueAmphur.amphur + this.mixinsThaiAddressSeparator + valueProvince.province + this.mixinsThaiAddressSeparator + valueTumbon.postcode);
                                }
                            });
                        }
                    });
                }
            });
    
            return items;
        },
        mixinsThaiAddressSetThaiAddressValue(value) {
            const selected = value.split(this.mixinsThaiAddressSeparator);

            this.$refs.thaiAddressDataTumbon.inputValue = selected[this.mixinsThaiAddressDataIndex.tumbon];
            this.$refs.thaiAddressDataAmphur.inputValue = selected[this.mixinsThaiAddressDataIndex.amphur];
            this.$refs.thaiAddressDataProvince.inputValue = selected[this.mixinsThaiAddressDataIndex.province];
            this.$refs.thaiAddressDataPostCode.inputValue = selected[this.mixinsThaiAddressDataIndex.postcode];
        },
        mixinsThaiFullAddressSetThaiAddressValue(value) {
            this.$refs.thaiFullAddressData.inputValue = value;
        },
        mixinsThaiFullAddressCheckValidInputAddress(e) {
            const target = e.relatedTarget;

            if ((!target || (target && !target.classList.contains('autocomplete-item'))) && this.mixinsThaiAddressList.filter(x => x === e.target.value).length === 0) {
                this.$refs.thaiFullAddressData.inputValue = '';
            }
        },
        mixinsThaiAddressCheckValidInputAddress(e, selector) {
            const target = e.relatedTarget;

            if ((!target || (target && !target.classList.contains('autocomplete-item'))) && this.mixinsThaiAddressList.filter(x => x.split(this.mixinsThaiAddressSeparator)[this.mixinsThaiAddressDataIndex[selector]] === e.target.value).length == 0) {
                this.$refs.thaiAddressDataTumbon.inputValue = '';
                this.$refs.thaiAddressDataAmphur.inputValue = '';
                this.$refs.thaiAddressDataProvince.inputValue = '';
                this.$refs.thaiAddressDataPostCode.inputValue = '';
            }
        }
    }
}