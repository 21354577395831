<template>
    <section class="box">
        <div class="cart-data">
            <h2><b-icon icon="receipt" class="icon"></b-icon> ใบกำกับภาษี</h2>
            
            <div class="info">
                <div class="content-yellow-note mb-3" v-if="!this.cartData.isAllowTaxRequest">
                    <span><b-icon icon="bell" class="icon"></b-icon> ร้านค้านี้ไม่รองรับการออกใบกำกับภาษี</span>
                </div>
                <div class="form" v-if="formTax">
                    <div class="tax-checker mb-1">
                        <div class="blocker" v-if="!this.cartData.isAllowTaxRequest"></div>

                        <checkbox
                            v-model="formTax.requireTax"
                            @input="updateRequireTax()">
                            ต้องการใบกำกับภาษี
                        </checkbox>
                    </div>

                    <template v-if="(formTax.requireTax || formTax.requireElecTax) && isTaxReady">
                        <div class="selected-data">
                            <label><b-icon icon="clipboard" class="icon"></b-icon> ชื่อ/บริษัท: {{ cartData.customer.taxName }}</label>
                        </div>
                        <div class="selected-data">
                            <label><b-icon icon="card-text" class="icon"></b-icon> เลขประจำตัวผู้เสียภาษี: {{ cartData.customer.taxId }}</label>
                        </div>
                        <div class="selected-data">
                            <label><b-icon icon="geo" class="icon"></b-icon> ที่อยู่ออกใบกำกับภาษี: {{ cartData.customer.taxAddress }} {{ cartData.customer.taxSubDistrict }} {{ cartData.customer.taxDistrict }} {{ cartData.customer.taxProvince }} {{ cartData.customer.taxPostCode }}</label>
                        </div>
                        <div class="selected-data" v-if="cartData.customer.requireElecTax">
                            <label><b-icon icon="envelope" class="icon"></b-icon> อีเมล: {{ cartData.customer.taxEmail }}</label>
                        </div>
                        <button class="btn btn-warning btn-edit" @click="editTax()">แก้ไข</button>
                    </template>

                    <div class="customer">
                        <template  v-if="(formTax.requireTax || formTax.requireElecTax) && !isTaxReady">
                            <div class="row">
                                <div class="col col-12 col-sm-6 col-lg-3">
                                    <div class="input-field">
                                        <label>เลขบัตรประชาชน/เลขนิติบุคคล</label>
                                        <input type="text"
                                            v-model="formTax.taxId"
                                            maxlength="50"
                                            placeholder="เลขบัตรประชาชน/เลขนิติบุคคล"
                                            :class="{ 'invalid': errorTax.taxId }" />
                                    </div>
                                </div>
                                <div class="col col-12 col-sm-6 col-lg-3">
                                    <div class="input-field">
                                        <label>ชื่อ-นามสกุล / ชื่อบริษัท</label>
                                        <input type="text"
                                            v-model="formTax.taxName"
                                            maxlength="50"
                                            placeholder="ชื่อ-นามสกุล / ชื่อบริษัท"
                                            :class="{ 'invalid': errorTax.taxName }" />
                                    </div>
                                </div>
                                <div class="col col-12" v-if="cartData.orderType === constants.ORDER_TYPE.DELIVERY">
                                    <div class="input-field mt-2">
                                        <checkbox
                                            v-model="isUseSameDeliveryAddress"
                                            @change="toggleAddress($event)">
                                            ใช้ที่อยู่เดียวกับข้อมูลจัดส่ง
                                        </checkbox>
                                    </div>
                                </div>
                                <template v-if="!isUseSameDeliveryAddress">
                                    <div class="col col-12 col-md-12">
                                        <div class="input-field">
                                            <label>ที่อยู่สำหรับออกใบกำกับภาษี</label>
                                            <input type="text"
                                                v-model="formTax.taxAddress"
                                                maxlength="250"
                                                placeholder="เลขที่/อาคาร/ซอย/ถนน"
                                                :class="{ 'invalid': errorTaxAddress.taxAddress }" />
                                        </div>
                                    </div>
                                    <div class="col col-12 col-sm-6 col-lg-3">
                                        <div class="input-field">
                                            <label>ตำบล/แขวง</label>
                                            <address-auto-complete
                                                ref="thaiAddressDataTumbon"
                                                placeholder="ตำบล/แขวง"
                                                :listData="mixinsThaiAddressList"
                                                v-model="formTax.taxSubDistrict"
                                                :inputClass="[(errorTaxAddress.taxSubDistrict ? 'invalid' : '')]"
                                                @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                                @input="mixinsThaiAddressSetAutoComplete($event, 'tumbon')"
                                                @blur="mixinsThaiAddressCheckValidInputAddress($event, 'tumbon')" />
                                        </div>
                                    </div>
                                    <div class="col col-12 col-sm-6 col-lg-3">
                                        <div class="input-field">
                                            <label>อำเภอ/เขต</label>
                                            <address-auto-complete
                                                ref="thaiAddressDataAmphur"
                                                placeholder="อำเภอ/เขต"
                                                :listData="mixinsThaiAddressList"
                                                v-model="formTax.taxDistrict"
                                                :inputClass="[(errorTaxAddress.taxDistrict ? 'invalid' : '')]"
                                                @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                                @input="mixinsThaiAddressSetAutoComplete($event, 'amphur')"
                                                @blur="mixinsThaiAddressCheckValidInputAddress($event, 'amphur')" />
                                        </div>
                                    </div>
                                    <div class="col col-12 col-sm-6 col-lg-3">
                                        <div class="input-field">
                                            <label>จังหวัด</label>
                                            <address-auto-complete
                                                ref="thaiAddressDataProvince"
                                                placeholder="จังหวัด"
                                                :listData="mixinsThaiAddressList"
                                                v-model="formTax.taxProvince"
                                                :inputClass="[(errorTaxAddress.taxProvince ? 'invalid' : '')]"
                                                @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                                @input="mixinsThaiAddressSetAutoComplete($event, 'province')"
                                                @blur="mixinsThaiAddressCheckValidInputAddress($event, 'province')" />
                                        </div>
                                    </div>
                                    <div class="col col-12 col-sm-6 col-lg-3">
                                        <div class="input-field">
                                            <label>รหัสไปรษณีย์</label>
                                            <address-auto-complete
                                                ref="thaiAddressDataPostCode"
                                                placeholder="รหัสไปรษณีย์"
                                                :listData="mixinsThaiAddressList"
                                                v-model="formTax.taxPostCode"
                                                :inputClass="[(errorTaxAddress.taxPostCode ? 'invalid' : '')]"
                                                @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                                @input="mixinsThaiAddressSetAutoComplete($event, 'postcode')"
                                                @blur="mixinsThaiAddressCheckValidInputAddress($event, 'postcode')" />
                                        </div>
                                    </div>
                                </template>
                                <div class="col col-12">
                                    <div class="tax-checker input-field mt-2">
                                        <div class="blocker" v-if="!this.cartData.isAllowElecTaxRequest"></div>
                                        <checkbox
                                            v-model="formTax.requireElecTax"
                                            @change="etaxInform($event)">
                                            ต้องการใบกำกับภาษีอิเล็กทรอนิกส์ (e-Tax/e-Receipt)
                                        </checkbox>
                                    </div>
                                </div>
                                <template v-if="formTax.requireElecTax">
                                    <div class="col col-12 col-sm-6">
                                        <div class="input-field">
                                            <label>อีเมล</label>
                                            <input type="text"
                                                v-model="formTax.taxEmail"
                                                maxlength="200"
                                                placeholder="อีเมลสำหรับส่ง e-Tax/e-Receipt"
                                                :class="{ 'invalid': errorElecTax.taxEmail }" />
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div class="text-center mt-3">
                                <button class="btn btn-warning btn-save-info" @click="saveTaxInfo()">บันทึกใบกำกับภาษี</button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ThaiAddress from '@/mixins/thaiAddress';
import Constants from '@/variables/constants';
import Helper from '@/services/helper';
import OrderService from '@/services/orderService';

export default {
    mixins: [ ThaiAddress ],
	data() {
		return {
            constants: Constants,
            isTaxReady: false,
            formTax: null,
            errorTax: this.initErrorTax(),
            errorTaxAddress: this.initErrorTaxAddress(),
            errorElecTax: this.initErrorElecTax(),
            isUseSameDeliveryAddress: this.cartData.orderType === Constants.ORDER_TYPE.DELIVERY ? true : false
		};
	},
    props: {
		cartData: Object
	},
    async mounted() {
        this.isTaxReady = this.checkTaxReady();
        this.formTax = this.initFormTax();
    },
	methods: {
        checkTaxReady() {
            if (this.cartData) {
                if (this.cartData.customer.taxId
                    && this.cartData.customer.taxName
                    && this.cartData.customer.taxAddress
                    && this.cartData.customer.taxSubDistrict
                    && this.cartData.customer.taxDistrict
                    && this.cartData.customer.taxProvince
                    && this.cartData.customer.taxPostCode) {
                    return true;
                }
            }

            return false;
        },
        initFormTax() {
            let requireTax = false;
            let requireElecTax = false;
            let taxEmail = '';

            if (this.cartData) {
                if (this.cartData.isAllowTaxRequest) {
                    requireTax = this.cartData.customer.requireTax;
                }

                if (this.cartData.isAllowElecTaxRequest) {
                    requireElecTax = this.cartData.customer.requireElecTax;
                    
                    if (requireElecTax) {
                        taxEmail = this.cartData.customer.taxEmail ? this.cartData.customer.email : '';
                    }
                }
            }

            return {
                requireTax: requireTax,
                requireElecTax: requireElecTax,
                taxId: this.cartData ? this.cartData.customer.taxId : '',
                taxName: this.cartData ? this.cartData.customer.taxName : '',
                taxAddress: this.cartData ? this.cartData.customer.taxAddress : '',
                taxSubDistrict: this.cartData ? this.cartData.customer.taxSubDistrict : '',
                taxDistrict: this.cartData ? this.cartData.customer.taxDistrict : '',
                taxProvince: this.cartData ? this.cartData.customer.taxProvince : '',
                taxPostCode: this.cartData ? this.cartData.customer.taxPostCode : '',
                taxEmail: taxEmail
            };
        },
        initErrorTax() {
            return {
                taxId: false,
                taxName: false
            };
        },
        initErrorTaxAddress() {
            return {
                taxAddress: false,
                taxSubDistrict: false,
                taxDistrict: false,
                taxProvince: false,
                taxPostCode: false
            };
        },
        initErrorElecTax() {
            return {
                taxEmail: false
            };
        },
        toggleAddress(value) {
            if (value) {
                this.formTax.taxAddress = this.cartData ? this.cartData.customer.address : '';
                this.formTax.taxSubDistrict = this.cartData ? this.cartData.customer.subDistrict : '';
                this.formTax.taxDistrict = this.cartData ? this.cartData.customer.district : '';
                this.formTax.taxProvince = this.cartData ? this.cartData.customer.province : '';
                this.formTax.taxPostCode = this.cartData ? this.cartData.customer.postCode : '';
            }
        },
        editTax() {
            this.isTaxReady = false;
        },
        updateRequireTax() {
            setTimeout(() => {
                this.submitTaxInfo(false);
            });
        },
        async saveTaxInfo() {
            if (this.validate()) {
                this.submitTaxInfo(true);
            }
        },
        async submitTaxInfo(isReload) {
            let loader = this.$modalLoader.render();
            const result = await OrderService.submitTaxInfo(this.formTax);
            loader.hide();

            if (result.data?.success) {
                if (isReload) {
                    this.isTaxReady = true;
                }

                this.$emit('doneSaveTax');
            } else {
                this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', result.data?.message));
            }
        },
        prepareCarData() {
            const selectedCar = JSON.parse(this.selectedCar);

            this.formCustomer.licenseNumber = selectedCar.licenseNumber;
            this.formCustomer.licenseProvince = selectedCar.licenseProvince;
            this.formCustomer.carBrand = selectedCar.carBrand;
            this.formCustomer.carModel = selectedCar.carModel;
        },
        validate() {
            this.errorTax = this.initErrorTax();
            this.errorTaxAddress = this.initErrorTaxAddress();
            let isValid = true;
            let isBlank = false;

            // Check Tax Field
            if (this.formTax.requireTax || this.formTax.requireElecTax) {
               for (const property in this.errorTax) {
                    if (!this.formTax[property]?.trim()) {
                        this.errorTax[property] = true;
                        isValid = false;
                        isBlank = true;
                    }
                }

                if (this.formTax.requireElecTax) {
                    for (const property in this.errorElecTax) {
                        if (!this.formTax[property]?.trim()) {
                            this.errorElecTax[property] = true;
                            isValid = false;
                            isBlank = true;
                        }
                    }
                }

                if (!this.isUseSameDeliveryAddress) {
                    for (const property in this.errorTaxAddress) {
                        if (!this.formTax[property]?.trim()) {
                            this.errorTaxAddress[property] = true;
                            isValid = false;
                            isBlank = true;
                        }
                    }
                }
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', 'โปรดกรอกข้อมูลใบกำกับภาษีให้ครบถ้วน'));
            }

            return isValid;
        },
        etaxInform(value) {
            if (value) {
                this.$swal(Helper.warningAlert('แจ้งเตือนนการออก e-tax', 'กรณีสั่งซื้อแบบติดตั้ง หากเลือกวันติดตั้งหลัง 15 ก.พ. 67 จะไม่สามารถใช้ e-tax ลดหย่อนภาษีได้ เนื่องจากเอกสารจะออกให้ตามวันที่ที่ติดตั้ง'));
            }
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/order/style/Cart.scss';
@import '@/components/order/style/CustomerForm.scss';
</style>