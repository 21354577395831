<template>
	<div class="cart">
		<div class="container">
			<div class="blank-space" v-if="!isAuthReady"></div>

			<template v-if="isAuthReady">
				<div class="panel-cart" v-if="cartData">
					<h1>สั่งซื้อสินค้าและบริการ</h1>

					<template v-if="promotionData">
						<div class="promotion-banner mb-3" :class="{ 'no-detail': !promotionData.detail }" @click="openModalPromotionInfo()">
							<img :src="promotionData.topBanner" :alt="promotionData.imageTitle" :title="promotionData.imageTitle" class="d-none d-sm-block" />
							<img :src="promotionData.topBannerMobile" :alt="promotionData.imageTitle" :title="promotionData.imageTitle" class="d-block d-sm-none" />
						</div>
					</template>

					<gadget-step-purchase class="mb-3" :step="4"></gadget-step-purchase>

					<div class="deliver-remark mb-3" v-if="cartData.orderType === constants.ORDER_TYPE.DELIVERY">
						{{ constants.DELIVERY_REMARK }}
						<span class="text-danger">*กรุณาตรวจสอบขนาด ยี่ห้อ รุ่นยาง ให้ถูกต้องก่อนชำระเงิน*</span>
					</div>

					<div class="announcement mb-3" v-if="cartData.announcement">
						<b-icon icon="megaphone" class="icon"></b-icon> {{ cartData.announcement }}
					</div>

					<cart-order-delivery
						ref="panelOrderDelivery"
						v-if="cartData.orderType === constants.ORDER_TYPE.DELIVERY"
						@doneSaveDeliveryInfo="loadCartData(true)"
						:cartData="cartData">
					</cart-order-delivery>

					<cart-order-product
						:cartData="cartData"
						@toggleService="toggleService($event)"
						@toggleDiscount="toggleDiscount($event)"
						@adjustQty="adjustQty($event)"
						@removeProduct="removeProduct($event)"
						@loadCartData="loadCartData(true)">
					</cart-order-product>

					<cart-order-store
						ref="panelOrderStore"
						:cartData="cartData"
						@doneSaveSchedule="loadCartData(false)">
					</cart-order-store>

					<cart-order-customer
						ref="panelOrderCustomer"
						v-if="cartData.orderType === constants.ORDER_TYPE.SERVICE"
						:cartData="cartData"
						@doneSaveCustomer="loadCartData(true)">
					</cart-order-customer>

					<cart-order-tax
						ref="panelOrderTax"
						:cartData="cartData"
						@doneSaveTax="loadCartData(true)">
					</cart-order-tax>

					<cart-order-payment
						:cartData="cartData"
						:paymentMethod="paymentMethod"
						ref="panelOrderPayment"
						@validateDelivery="validateDelivery()"
						@validateSchedule="validateSchedule()"
						@validateCustomer="validateCustomer()"
						@validateTax="validateTax()"
						@changePaymentMethod="changePaymentMethod($event)">
					</cart-order-payment>

					<template v-if="promotionData">
						<div class="promotion-banner" :class="{ 'no-detail': !promotionData.detail }" v-if="promotionData.bottomBanner && promotionData.bottomBannerMobile" @click="openModalPromotionInfo()">
							<img :src="promotionData.bottomBanner" :alt="promotionData.imageTitle" :title="promotionData.imageTitle" class="d-none d-sm-block" />
							<img :src="promotionData.bottomBannerMobile" :alt="promotionData.imageTitle" :title="promotionData.imageTitle" class="d-block d-sm-none" />
						</div>
					</template>
				</div>

				<div class="no-data" v-else>
					<b-icon icon="box-seam"></b-icon>
					<span>ยังไม่มีรายการสินค้าที่สั่งซื้อ</span>
				</div>
			</template>
		</div>

		<modal-promotion-info
			@close="closeModalPromotionInfo($event)"
			:promotionData="promotionData"
			:isDisplay="isOpenModalPromotionInfo">
		</modal-promotion-info>

		<modal-order-payment
			@close="closeModalPayment()"
			:isDisplay="isOpenModalPayment"
			:paymentData="paymentData"
			:cartData="cartData">
		</modal-order-payment>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import OrderService from '@/services/orderService';
import UserService from '@/services/userService';
import RouterPath from '@/router/path';
import Constants from '@/variables/constants';
import GadgetStepPurchase from '@/components/gadget/StepPurchase';
import ModalPromotionInfo from '@/components/order/ModalPromotionInfo';
import ModalOrderPayment from '@/components/order/ModalPayment';
import CartOrderDelivery from '@/components/order/OrderDelivery';
import CartOrderProduct from '@/components/order/OrderProduct';
import CartOrderStore from '@/components/order/OrderStore';
import CartOrderPayment from '@/components/order/OrderPayment';
import CartOrderCustomer from '@/components/order/OrderCustomer';
import CartOrderTax from '@/components/order/OrderTax';

export default {
	components: {
		GadgetStepPurchase,
		ModalPromotionInfo,
		ModalOrderPayment,
		CartOrderDelivery,
		CartOrderProduct,
		CartOrderStore,
		CartOrderPayment,
		CartOrderCustomer,
		CartOrderTax
	},
	data() {
		return {
			constants: Constants,
			isAuthReady: false,
			cartData: null,
			paymentMethod: '',
			promotionData: null,
			paymentData: null,
			isOpenModalPromotionInfo: false,
			isOpenModalPayment: false
		};
	},
	mounted() {
		this.loadCartData(true);
	},
	methods: {
		openModalPromotionInfo() {
			if (this.promotionData.detail) {
				this.isOpenModalPromotionInfo = true;
			}
		},
		closeModalPromotionInfo() {
			this.isOpenModalPromotionInfo = false;
		},
		openModalPayment(data) {
			this.paymentData = data;
            this.isOpenModalPayment = true;
        },
        closeModalPayment() {
            this.isOpenModalPayment = false;
        },
		async loadCartData(isShowLoading) {
			let loader = isShowLoading ? this.$modalLoader.render() : null;

			if (localStorage.cartData) {
				await this.triggerPurchaseAfterLogin();
				localStorage.removeItem('cartData');
			}

			const result = await OrderService.getCartData();

			if (result.status == 401) {
				UserService.clearUserToken();
				this.$router.push(RouterPath.LOGIN);
			} else if (result.data) {
				this.cartData = result.data.cartData;
				this.promotionData = result.data.promotionData;
				this.isAuthReady = true;

				// Check current payment method
				this.checkSelectedPaymentMethod();

				// Alert if product is adjusted by seller
				if (!result.data.isValid) {
					this.$swal(Helper.warningAlert('กรุณาตรวจสอบรายการใหม่อีกครั้ง', result.data.message)).then(() => {
						if (result.data.redirectLink) {
							this.$router.push(result.data.redirectLink);
						}
					});
				}
			}

			if (isShowLoading) {
				loader.hide();
			}
		},
		checkSelectedPaymentMethod() {
			if (this.cartData) {
				if ((!this.cartData.paymentOptions.internetBanking && this.paymentMethod === 'internetBanking')
					|| (!this.cartData.paymentOptions.creditCard && this.paymentMethod === 'card')
					|| (!this.cartData.paymentOptions.freeInstallment && this.paymentMethod === 'installment1')
					|| (!this.cartData.paymentOptions.interestInstallment && this.paymentMethod === 'installment2')) {
					this.paymentMethod = '';
				}
			}
		},
		async triggerPurchaseAfterLogin() {
            const param = JSON.parse(localStorage.cartData);
            const result = await OrderService.addToCart(param);
            
            if (result.data.success) {
                this.$root.$emit('rootSetCartIcon');
            }
        },
		async adjustQty(data) {
			let loader = this.$modalLoader.render();
			const param = {
				operand: data.operand,
				sellerProductId: data.sellerProductId
			};
			
			const result = await OrderService.adjustQty(param);

			if (!result.data?.success) {
				this.$swal(Helper.warningAlert('ไม่สามารถทำรายการได้', result.data.message));
			}
			
			this.loadCartData(true);
			loader.hide();
		},
		async removeProduct(data) {
			let loader = this.$modalLoader.render();
			const param = {
				id: data.sellerProductId
			};
			
			const result = await OrderService.removeProduct(param);

			if (!result.data?.success) {
				this.$swal(Helper.warningAlert('ไม่สามารถทำรายการได้', result.data.message));
			}
			
			this.loadCartData(true);
			loader.hide();
		},
		async toggleService(data) {
            let loader = this.$modalLoader.render();
			const param = {
				id: data.item.id,
				include: data.include
			};
			
			await OrderService.toggleService(param);
			
			this.loadCartData(true);
			loader.hide();
        },
		async toggleDiscount(data) {
            let loader = this.$modalLoader.render();
			const param = {
				id: data.item.promoId,
				include: data.include
			};
			
			await OrderService.toggleDiscount(param);
			
			this.loadCartData(true);
			loader.hide();
        },
		async changePaymentMethod(param) {
			let loader = this.$modalLoader.render();
			this.paymentMethod = param.method;
			
			// Change payment method
			const paramChangePayment = {
				method: param.method,
				installmentPeriod: param.installmentPeriod
			};

			await OrderService.changePaymentMethod(paramChangePayment);
			
			// Load Cart
			await this.loadCartData(false);

			loader.hide();

			// Open Payment Modal
			const paramPaymentModal = {
				period: param.installmentPeriod,
				paid: param.paid,
				method: param.method
			};

			this.openModalPayment(paramPaymentModal);
		},
		validateDelivery() {
			this.$refs.panelOrderDelivery.validate();
		},
		validateSchedule() {
			this.$refs.panelOrderStore.validate();
		},
		validateCustomer() {
			this.$refs.panelOrderCustomer.validate();
		},
		validateTax() {
			this.$refs.panelOrderTax.validate();
		}
	},
	metaInfo() {
		const title = 'รายการสั่งซื้อ | YELLOWTiRE';
		const desc = 'รายการสั่งซื้อและเลือกร้านคัาเพื่อใช้บริการ';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.cart {
	padding: 15px 0;

	.panel-cart {
		margin-bottom: 20px;

		h1 {
			margin: 15px 0;
			font-size: 28px;
			text-align: center;
			font-family: 'Open Sans', 'Kanit';
			color: #333;
			font-weight: bold;

			@media only screen and (max-width: $screenMedium) {
				margin-top: 0;
				font-size: 22px;
			}
		}

		.promotion-banner {
			cursor: pointer;

			&.no-detail {
				cursor: default;
			}

			img {
				display: block;
				max-width: 100%;
				width: 600px;
				margin: auto;

				@media only screen and (max-width: $screenExtraSmall) {
					width: 100%;
				}
			}
		}

		.deliver-remark {
			padding: 15px;
			background: #fff;
			border: 1px dashed #aaa;
			font-size: 12px;

			span {
				display: block;
			}
		}

		.announcement {
			background: #ffb139;
			padding: 15px;
			font-size: 14px;

			.icon {
				margin-right: 10px;
			}
		}
	}
}
</style>